<template>
  <div class="app-container v">
    <el-tabs class="no-flex" v-model="tab" @tab-click="handleTabChange">
      <el-tab-pane name="default" label="统一设置" />
      <el-tab-pane name="target" label="指定经销商" />
    </el-tabs>

    <template v-if="tab === 'default'">
      <div class="head-container no-flex">
        <el-button
          class="filter-item"
          size="mini"
          type="primary"
          icon="el-icon-plus"
          @click="addFactory"
          >添加</el-button
        >
        <el-button
          class="filter-item"
          size="mini"
          type="info"
          icon="el-icon-refresh-left"
          @click="loadOder"
          >刷新</el-button
        >
      </div>
      <el-table
        key="t1"
        v-loading="loading"
        :data="dataFactory"
        size="small"
        style="margin-top: 10px"
        height="200px"
      >
        <el-table-column label="限购次数">
          <template slot-scope="scope">
            <el-input-number
              v-model="scope.row.buyTimes"
              :min="0"
              :max="99999"
              controls-position="right"
              size="mini"
            />
          </template>
        </el-table-column>
        <el-table-column label="起止日期" width="300">
          <template slot-scope="scope">
            <el-date-picker
              v-model="scope.row.times"
              type = "daterange"
              value-format="timestamp"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :default-time="['00:00:00', '23:59:59']"
              style="width: 290px"
            />
          </template>
        </el-table-column>
        <el-table-column label="添加时间">
          <template slot-scope="scope">
            {{ new Date(scope.row.createAt).format("yyyy-MM-dd hh:mm:ss") }}
          </template>
        </el-table-column>
        <el-table-column width="100" align="center">
          <div class="row-commands" slot-scope="scope">
            <el-button
              type="text"
              size="mini"
              @click="doSaveSingDealer(scope.row)"
              >保存</el-button
            >
            <el-button type="text" size="mini" @click="subDelete(scope.row.id)"
              >删除</el-button
            >
          </div>
        </el-table-column>
      </el-table>
    </template>

    <template v-else>
      <div class="head-container no-flex">
        <el-button
          type="danger"
          class="filter-item"
          icon="el-icon-delete"
          style="margin-left: 8px"
          :disabled="!selection || !selection.length"
          @click="deleteSelect"
          >批量删除</el-button
        >
        <el-button
          class="filter-item"
          :loading="addLoading"
          type="primary"
          @click="addDealer"
          >添加</el-button
        >
        <el-button
          type="info"
          class="filter-item"
          @click="uploadOrder"
          icon="el-icon-upload2"
          style="margin-left: 8px"
          >导入</el-button
        >
        <el-input
          v-model="query.keywords"
          :maxlength="20"
          placeholder="输入经销商名称/编码搜索"
          @keypress.enter.native="toQuery"
          clearable
          class="filter-item"
          style="width: 220px"
        />
        <el-select v-model="query.dateRange" class="filter-item" filterable clearable placeholder="有效范围" style="width: 120px;" @change="toQuery">
          <el-option value="month" label="指定日期" />
          <el-option value="forever" label="长期有效" />
        </el-select>
        <el-date-picker v-if="query.dateRange==='month'"
        class="filter-item"
            v-model="query.times"
            type="daterange"
            value-format="timestamp"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :default-time="['00:00:00', '23:59:59']"
            style="width: 240px"
            @change="toQuery"
          />
          <el-button
            size="mini" class="filter-item"
            icon="el-icon-search"
            @click="toQuery"
            type="success"
            >搜索</el-button>
        
      </div>
      <!--表格渲染-->
      <el-table
        key="t2"
        v-loading="loading"
        :data="dataDealer"
        row-key="id"
        height="200px"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55" />
        <el-table-column
          prop="disErpCode"
          label="经销商编码"
          width="160"
          :show-overflow-tooltip="true"
        />
        <el-table-column
          prop="disName"
          label="经销商名称"
          :show-overflow-tooltip="true"
        />
        <el-table-column label="限购次数" width="110">
          <template slot-scope="scope">
            <el-input-number
              v-model="scope.row.buyTimes"
              :min="0"
              :max="99999"
              controls-position="right"
              size="mini"
              style="width: 100px"
            />
          </template>
        </el-table-column>
        <el-table-column label="起止日期" width="350">
          <template slot-scope="scope">
            <el-date-picker
              v-model="scope.row.times"
              type = "datetimerange"
              value-format="timestamp"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :default-time="['00:00:00', '23:59:59']"
              style="width: 340px"
            />
          </template>
        </el-table-column>
        <!-- <el-table-column label="结束日期">
          <template slot-scope="scope">
            <el-date-picker
              v-model="scope.row.expireAt"
              value-format="timestamp"
              placeholder="结束日期"
              style="width: 130px"
              default-time="23:59:59"
            />
          </template>
        </el-table-column> -->
        <el-table-column label="添加时间" width="140">
          <template slot-scope="scope">
            {{ new Date(scope.row.createAt).format("yyyy-MM-dd hh:mm:ss") }}
          </template>
        </el-table-column>
        <el-table-column width="100" align="center" fixed="right">
          <div class="row-commands" slot-scope="scope">
            <el-button
              type="text"
              size="mini"
              @click="doSaveSingDealer(scope.row)"
              >保存</el-button
            >
            <el-button type="text" size="mini" @click="subDelete(scope.row.id)"
              >删除</el-button
            >
          </div>
        </el-table-column>
      </el-table>
      <el-pagination
        :total="totalElements"
        :current-page="pageDealer + 1"
        :page-size="sizeDealer"
        @size-change="sizeChangeDealer"
        @current-change="pageChangeDealer"
        layout="prev, pager, next, jumper"
      />
    </template>


    <facForm ref="facForm" />

    <eForm ref="form0" @change="handleChangeAddDealer" />
    <orderForm ref="orderForm0" @change="loadOder" />
  </div>
</template>

<script>
import request from "@/utils/request";
import eForm from "./form";
import orderForm from "./order-import";
import facForm from "./facForm";
export default {
  components: { eForm, orderForm, facForm },
  data() {
    return {
      tab: "default",
      pageDealer: 0,
      sizeDealer: 10,
      totalElements: null,
      dialog: false,
      loading: false,
      addLoading: false,
      doSaveLoading: false,
      delLoading: false,
      selection: [],
      times: {
        dayTimes: 1,
      },
      dataDealer: [], //指定经销商
      dataFactory: [],
      buyLimit: {
        id: null,
      },
      current: null,
      //
      query: {
        keywords: null,
      },
      pickerOptions: {
        shortcuts: [
          {
            text: "当前月",
            onClick(picker) {
              const now = new Date();
              const start = new Date(
                now.getFullYear(),
                now.getMonth(),
                1,
                0,0,0
              );
              const end = new Date(
                now.getFullYear(),
                now.getMonth()+1,
                0,
                23,
                59,
                59
              );
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
    };
  },
  created() {
    this.loadOder();
  },
  methods: {
    addFactory() {
      this.$refs.facForm.dialog = true;
    },
    toQuery(){
      this.pageDealer = 0;
      this.loadOder();
    },
    handleSelectionChange(sels) {
      this.selection = sels;
    },
    pageChangeDealer(e) {
      this.pageDealer = e - 1;
      this.loadOder();
    },
    sizeChangeDealer(e) {
      this.pageDealer = 0;
      this.sizeDealer = e;
      this.loadOder();
    },
    handleTabChange(tab) {
      if (tab.name === "unified") {
        // this.load();
      } else {
        // this.loadSpecialRule(this.current);
      }
    },
    deleteSelect(){
      let idList = this.selection.map(f=>f.id);
      this.$confirm("确定删除所选择的数据吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        request({
          url: "api/performanceBuyLimit",
          method: "delete",
          data:idList,
        })
          .then((res) => {
            this.loadOder();
            this.$notify({
              title: "删除成功",
              type: "success",
              duration: 2500,
            });
          })
          .catch((err) => {});
      });
    },
    loadOder() {
      this.ruleLoading = true;
      this.loading = true;
      request({
        url: "/api/performanceBuyLimitForAll",
        method: "get",    
      })
        .then((res) => {
          if (res) {
            // this.times.id = res.id;
            // this.times.dayTimes = Object.assign(res.buyTimes || 0);
            res.forEach(d=>{
              if(d.beginAt && d.expireAt){
                d.times = [d.beginAt,d.expireAt];
              }
            })
            this.dataFactory = res;
            
          }
        })
        .finally((_) => {
          this.ruleLoading = false;
        });

      let params = {
        page: this.pageDealer,
        // page: 0,
        size: this.sizeDealer,
        sort: ["createAt,desc"],
        keywords: this.query.keywords,
        dateRange:this.query.dateRange
      };
      if(params.dateRange==='month' && this.query.times && this.query.times.length){
        params.beginAt = this.query.times[0];
        params.expireAt = this.query.times[1];
      }
      request({
        url: "/api/performanceBuyLimit",
        method: "get",
        params,
      })
        .then((res) => {
          if (res) {
            res.content.forEach(d=>{
              if(d.beginAt && d.expireAt){
                d.times = [d.beginAt,d.expireAt];
              }
            })
            this.dataDealer = res.content;            
            this.totalElements = res.totalElements;
          }
        })
        .finally((_) => {
          this.loading = false;
        });
    },

    handleChangeAddDealer(row) {
      let data = {
        buyTimes: this.times.dayTimes,
        disEntId: row.enterpriseId,
        disErpCode: row.erpId,
        disName: row.alias || "--",
      };
      request({
        url: "api/performanceBuyLimit",
        method: "post",
        data: data,
      }).then((res) => {
        this.$notify({
          title: "保存成功",
          type: "success",
          duration: 2500,
        });
        this.pageDealer = 0;
        this.loadOder();
      });
    },
    // 添加指定经销商
    addDealer() {
      this.$refs.form0.dialog = true;
    },
    doSaveSingDealer(value) {
      if (value.id) {
        let data = JSON.parse(JSON.stringify(value));        
        if(data.times!=null && data.times.length>1){
          data.beginAt = data.times[0];
          data.expireAt = data.times[1];
        }
        request({
          url: "api/performanceBuyLimit",
          method: "put",
          data,
        }).then(() => {
          this.$notify({
            title: "保存成功",
            type: "success",
            duration: 2500,
          });
        });
      }
    },
    subDelete(id) {
      this.$confirm("确定删除本条数据吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        request({
          url: "api/performanceBuyLimit/" + id,
          method: "delete",
        })
          .then((res) => {
            this.loadOder();
            this.$notify({
              title: "删除成功",
              type: "success",
              duration: 2500,
            });
          })
          .catch((err) => {});
      });
    },
    uploadOrder() {
      this.$refs.orderForm0 && this.$refs.orderForm0.resetForm();
    },
  },
};
</script>